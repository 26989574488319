import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import Reanalysisicon from "assets/reanalysis_icon.svg";
import { Link } from "gatsby";
import { useTopicIdentifier } from "hooks/Forms/useTopicIdentifier";
import ToolsBreadcrumb from "components/ToolsBredcrumb";

const TopicdetectorTool = () => {
  const { status, mutateAsync } = useTopicIdentifier();

  const [detectedTopicText, setDetectedTopicText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [wordCount, setWordCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data["message"] === "") {
      alert("Please enter text in the textarea box");
      return false;
    }

    setLoading(true);

    const postData = {
      tool_name: "identifyTopic",
      user_text: data.message,
      ...formCommonPostData(),
    };

    try {
      const response = await mutateAsync(postData);
      setLoading(false);

      const domain = response?.result?.domain || response?.result?.Domain;
      const specialization =
        response?.result?.Specialization || response?.result?.specialization;

      if (domain && specialization) {
        setDetectedTopicText(`${domain} (${specialization})`);
      } else if (domain) {
        setDetectedTopicText(domain);
      } else {
        setDetectedTopicText("");
      }
    } catch (error) {
      setLoading(false);
      console.error(
        error.message || "An error occurred while detecting the topic text."
      );
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value.trim();
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    if (words.length > 1000) {
      e.target.value = words.slice(0, 1000).join(" ");
      return;
    }
    setWordCount(words.length);
    setDetectedTopicText("");
  };
  // console.log(detectedTopicText,"detectiontopictext")
  const clearText = () => {
    setWordCount(0);
    setDetectedTopicText("");

    reset({ message: "" });
  };

  return (
    <div className="bg-[#F5F5F5] ">
       <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
    
      <div className="py-12 md:py-24 max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
        <div>
          <h1 className="text-[#5B93FF] text-center md:text-start text-base font-primary font-semibold uppercase">
            TOPIC DETECTION TOOL
          </h1>
          <h2 className="text-[#0A2641] pt-3 text-center md:text-start font-bold text-[28px] lg:text-[40px] leading-[48px] font-primary">
          Match Texts with Specialized Translators for Enhanced Quality
          </h2>
          <p className="text-[#424242] text-center md:text-start text-base md:text-lg font-opensans leading-[26px] pt-6">
            Enter your text in any language, and the topic identifier tool will
            automatically detect the main domain, whether it is legal, medical,
            marketing, or any other subject field.
          </p>
        </div>
        <div>
          <form
            action=""
            className="relative shadow-lg rounded-lg bg-white"
            onSubmit={handleSubmit(onSubmit)}
          >
            <textarea
              // dir={inputLang === "he" || inputLang === "ar" ? "rtl" : "ltr"}
              placeholder="Type your text here"
              name="message"
              id="message"
              {...register("message")}
              className="pl-5 pr-8 py-5 min-h-[240px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full"
              onChange={handleTextChange}
            ></textarea>

            {wordCount > 0 && (
              <p
                className="absolute top-[14px] right-[16px] w-max cursor-pointer"
                onClick={clearText}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19.5787 17.5581C19.8469 17.8263 19.9977 18.1902 19.9977 18.5696C19.9977 18.9489 19.8469 19.3128 19.5787 19.581C19.3104 19.8493 18.9466 20 18.5672 20C18.1878 20 17.824 19.8493 17.5557 19.581L10.0006 12.0235L2.44313 19.5787C2.17487 19.8469 1.81104 19.9976 1.43166 19.9976C1.05228 19.9976 0.688446 19.8469 0.420187 19.5787C0.151927 19.3104 0.00122071 18.9466 0.0012207 18.5672C0.0012207 18.1878 0.151927 17.824 0.420187 17.5557L7.97768 10.0006L0.422566 2.4431C0.154307 2.17484 0.00360069 1.81101 0.0036007 1.43163C0.0036007 1.05225 0.154307 0.688415 0.422566 0.420156C0.690826 0.151897 1.05466 0.00118977 1.43404 0.00118977C1.81342 0.00118977 2.17725 0.151897 2.44551 0.420156L10.0006 7.97765L17.5581 0.418966C17.8264 0.150706 18.1902 -6.32041e-09 18.5696 0C18.949 6.32041e-09 19.3128 0.150706 19.5811 0.418966C19.8493 0.687225 20 1.05106 20 1.43044C20 1.80982 19.8493 2.17365 19.5811 2.44191L12.0236 10.0006L19.5787 17.5581Z"
                    fill="#69AAE8"
                  />
                </svg>
                {/* &#10005; */}
              </p>
            )}

            <div className="flex justify-between items-center p-4">
              <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                word count: {wordCount}
              </p>
              <button
                type="submit"
                disabled={!!detectedTopicText}
                className={` ${
                  detectedTopicText
                    ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                    : "bg-lightBlue text-white"
                } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-2`}
              >
                {/* {loading ? <Loader /> : "Detect language"} */}
                Identify topic
              </button>
            </div>
          </form>
          <div className="mt-4 rounded-lg bg-white w-full md:flex justify-between items-center px-4 py-6 shadow-lg">
            <p className="text-[#858585] font-opensans text-semibold leading-6">
              Primary Domain:
            </p>
            <p className="text-[#858585] mt-4 md:mt-0 font-opensans text-semibold leading-6">
              {status === "loading" ? (
                <p className="flex">
                  Loading...
                  <Loader />
                </p>
              ) : detectedTopicText ? (
                <p className="flex items-center gap-2 w-max">
                  <span className="text-[#27862A] text-lg font-bold leading-6 font-opensans">
                    {detectedTopicText}
                  </span>
                </p>
              ) : (
                "--"
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicdetectorTool;
