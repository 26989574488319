
import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const detectText = async (postData) => {

return http().post(endpoints.forms.aiTools, postData);
};

export function useTopicIdentifier() {
  return useMutation(detectText, {
    onError: (error) => {
      const errorMessage =
        error.message || "An error occurred while detecting the text.";
      alert(errorMessage);
    },
  });
}